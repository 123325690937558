<template>
  <div class="flex flex-wrap py-10">
    <p>Full path (include "/" signs): </p>
    <input class="p-2 border-b border-black outline-none mx-10" v-model="fullPath" type="text">
    <span @click="addPage" class="cursor-pointer p-2 bg-green-400 rounded-md">Add</span>
  </div>
</template>

<script>
import { HTTP } from '../axios/axios'

export default {
  name: 'addPage',
  data () {
    return {
      fullPath: ''
    }
  },
  methods: {
    addPage () {
      HTTP.post('/zigzag', { fullPath: this.fullPath, page: '/' + this.fullPath.split('/').pop(), lang: 'en', zigzags: [] })
      HTTP.post('/zigzag', { fullPath: this.fullPath, page: '/' + this.fullPath.split('/').pop(), lang: 'ru', zigzags: [] })
      HTTP.post('/zigzag', { fullPath: this.fullPath, page: '/' + this.fullPath.split('/').pop(), lang: 'az', zigzags: [] })
    }
  }
}
</script>
